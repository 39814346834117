import React from "react";
import styled from "styled-components";

import { SanityProductPreview } from "@graphql-types";
import { Container, H3 } from "@util/standard";
import { Button } from ".";
import { useCheckScreenWidth } from "@util/hooks";
import ProductCard from "./productCard";
import { MOBILE_BREAKPOINT } from "@util/constants";

interface Props {
  data: SanityProductPreview;
}

const Title = styled(Container)`
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

function ProductPreview({ data }: Props) {
  const { isLaptopWidth } = useCheckScreenWidth();

  if (data == null) {
    return null;
  }
  return (
    <Container width="65%" margin="100px auto" flexDirection="column">
      <Title>
        {data.header && (
          <H3 mobileFontSize={22} margin="0 25px 0 0 " fontFamily="proximaNova">
            {data.header}
          </H3>
        )}
        {data.blockLink && data.blockLink.url && (
          <Button
            padding={isLaptopWidth ? "15px 0 0 0" : "10px 0 0 0"}
            theme="textBlack"
            text={data.blockLink.linktext as string}
            linkTo={data.blockLink.url as string}
          />
        )}
      </Title>
      {data.productRefs && (
        <Container width="100%" flexDirection={isLaptopWidth ? "row" : "column"}>
          {data.productRefs.map((product, index) => {
            if (product == null || data.productRefs == null) {
              return null;
            }

            const isLast = index === data.productRefs.length - 1;

            return <ProductCard data={product} key={product._id} margin={isLast ? "20px 0px 0 0" : "20px 15px 0 0"} />;
          })}
        </Container>
      )}
    </Container>
  );
}

export default ProductPreview;
