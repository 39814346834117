import { Button, Image } from "@components/global";
import Accordion from "@components/global/accordion";
import CartSuccessToast from "@components/global/cartSuccessToast";
import { Query, SanityProduct } from "@graphql-types";
import { useStore } from "@state/store";
import { icons } from "@util/constants";
import { useCheckScreenWidth } from "@util/hooks";
import { addItemToCart, getProduct } from "@util/shopifyHelper";
import { A, Container, H2, P } from "@util/standard";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Dropdown } from "react-dropdown-now";
import { toast } from "react-toastify";
import styled from "styled-components";

interface Props {
  data: SanityProduct;
}

interface FilterDropDownProps {
  text: string;
}

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  color: black;

  .rdn-control {
    width: 100%;
    border: 1px solid black;
    padding-left: 20px;
  }

  .is-selected {
    width: 100%;
    background: transparent;
  }

  .rdn-drop {
    overflow-y: visible;
    max-height: none;
    border: 1px solid black;
    z-index: 4;
    width: 100%;
  }

  .rdn-control-arrow-icon {
    border-style: none;
    background: url(${icons.arrow});
    width: 14px;
    height: 9px;
    margin-right: 5px;
  }
  .rdn-drop-menu-option {
    color: black;
    padding: 0 0 0 20px;
    font-weight: 600 !important;

    &:hover {
      background: transparent;
    }
  }
`;

const Price = styled(P)<{ sale?: boolean }>`
  font-size: 30px;

  ${({ sale }) =>
    sale &&
    `
    font-size: 15px;
    text-decoration: line-through;
    opacity: 0.4;`};
  margin-right: 20px;
`;

function ProductHero({ data }: Props) {
  const FilterButton = ({ text }: FilterDropDownProps) => {
    return (
      <Container>
        <P width="100%" margin="7px 0px">
          {text}
        </P>
      </Container>
    );
  };

  const { sanityShippingInfo }: Query = useStaticQuery(graphql`
    {
      sanityShippingInfo {
        shippingText
      }
    }
  `);

  const { productImages, productName, author, summary, aboutProduct } = data;

  const [selectedImage, setSelectedImage] = useState(0);
  const [productQuantity, setproductQuantity] = useState(1);
  const { checkout } = useStore();
  const { isLaptopWidth } = useCheckScreenWidth();

  const setDropDownOptions = () => {
    let count = 1;
    let options = [{ value: 1, label: "Qty", view: <FilterButton text="Qty" /> }];
    for (let i = 1; i < 10; i++) {
      options.push({ value: count, label: `${count}`, view: <FilterButton text={`${count}`} /> });
      count++;
    }
    return options;
  };

  const handleClick = async () => {
    console.log("clicked");
    console.log({ checkout });

    if (checkout) {
      getProduct(data)
        .then(product => {
          const productId = product[0].variants[0].id;
          addItemToCart(checkout.id, productId as string, productQuantity);
          toast.success(CartSuccessToast);
        })
        .catch(error => {
          toast.error(`Something went wrong when adding Item to Cart`);
        });
    }
  };
  return (
    <Container
      width="65%"
      tabletWidth="100%"
      margin="12% auto 13% auto"
      tabletMargin="25% auto 80px auto"
      mobileMargin="30% auto 80px auto"
      flexDirection="column"
    >
      <A
        href="/shop"
        margin={isLaptopWidth ? "0 0 15px 0" : "0 4.5% 15px 4.5%"}
        color="black"
      >{`< Back to Products`}</A>
      <Container flexDirection={isLaptopWidth ? "row" : "column"}>
        {productImages && (
          <Container flexDirection="column" width="50%" tabletWidth="91%" margin="0 10% 0 0" tabletMargin="auto">
            <Container height="564px" width="auto" tabletWidth="100%" tabletHeight="65vh">
              <Image objectFit="cover" image={productImages[selectedImage]?.asset?.gatsbyImageData} />
            </Container>
            <Container>
              {productImages.length > 1 &&
                productImages.map((image, index) => {
                  if (image == null) {
                    return null;
                  }
                  const isLast = index === productImages.length - 1;
                  return (
                    <Container
                      width="92px"
                      height="92px"
                      tabletWidth="84px"
                      tabletHeight="84px"
                      margin={isLast ? "2px 0" : "2px 2px 2px 0"}
                      onClick={() => setSelectedImage(index)}
                      key={image._key}
                    >
                      <Image objectFit="cover" image={image.asset?.gatsbyImageData} />
                    </Container>
                  );
                })}
            </Container>
          </Container>
        )}
        <Container width="40%" tabletWidth="80%" tabletMargin="60px auto 0 auto" flexDirection="column">
          <H2 color="darkerGreen">{productName}</H2>
          {author && <P fontWeight="bold">{author}</P>}
          {summary && <P noMargin>{summary}</P>}
          <Container margin="40px 0">
            <Price noMargin sale={data.onSale as boolean}>
              ${data.price}
            </Price>
            {data.onSale && <Price noMargin>${data.salePrice}</Price>}
          </Container>
          <Container margin="30px 0">
            <Container margin="0 20px 0 0">
              <StyledDropdown
                placeholder="Qty"
                options={setDropDownOptions()}
                value={1}
                onChange={option => setproductQuantity(option.value as number)}
              />
            </Container>
            <Button theme="block" text="Add to Cart" onClick={() => handleClick()} />
          </Container>
          {sanityShippingInfo && sanityShippingInfo.shippingText && (
            <Accordion text="DELIVERY" noBorderBottom>
              <P margin="5px 0 15px 0">{sanityShippingInfo.shippingText}</P>
            </Accordion>
          )}
          {aboutProduct && (
            <Accordion text="ABOUT">
              <P margin="5px 0 15px 0">{aboutProduct}</P>
            </Accordion>
          )}
        </Container>
      </Container>
    </Container>
  );
}

export default ProductHero;
